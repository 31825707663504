ul{
    padding: 0;
}
ul li {
    color: #FFFFff;
    padding: 10px;
    list-style-type: none;
    margin: 0;
}

.TitleName {
    color: #00A991;
    font-weight: 500;
    font-size: 18px;
}

.footerText {
    text-align: center;
    color: #F8FAFC;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0;
}


@media screen and (max-width: 768px) {
.footerBox{
    margin-top: 20px;
}
 .footer-tag{
     padding-top: 20px;
 }

}
