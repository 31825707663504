.modal-form {
    border-radius: 20px;
}

.donateHeigh{
    
}

.donate-img {
    /* margin-top: 150px; */
    padding: 0;
    position: relative;
}

.donateBox {
    margin-top: -70px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    min-height: 130px;
}

.donateBox .donateTitle {
    background: #00A991;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.donateBox .donateTitle {
    text-align: center;
    padding: 30px;
    font-weight: 500;
    font-size: 25px;
    color: #FFFFFF;
}

.donateBox .donateDatail-box {
    background: #ffffff;

    font-weight: 400;
    font-size: 14px;
    color: #252A31;
}

.donateBox .donateDatail-box {
    padding: 40px;
}

.ListTitle {
    font-weight: 700;
    font-size: 35px;
    color: #252A31;

}

.detailList {
    font-weight: 400;
    font-size: 14px;
    color: #252A31;
}

.btnDonateTree {
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 15px;
    padding-top: 20px;
    width: 100%;
    background: #00A991;
    height: 65px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    font-weight: 400;
    font-size: 14px;
    border: 0;
    color: #ffffff;
}


.btnDonate {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 15px;
    width: 100%;
    background: #FFFFFF;
    height: 70px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    font-weight: 400;
    font-size: 14px;
    border: 0;
    color: #000000;
}

.btnDonate:hover {
    width: 100%;
    background: #FFFFFF;
    height: 70px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    font-weight: 400;
    font-size: 14px;
    border: 0;
    color: #000000;
}

.titleDetailTitle {
    color: #000000;

    margin-top: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 36px;
}

.titleDetailText {
    font-weight: 300;
    font-size: 18px;
    color: #000000;
    text-align: center
}

.imgQRCode {
    /* padding: 50px;
    width: 363px;
    height: 350px; */
}

.formInput[type="text"],
[type="select"] [type="email"] {
    height: 40px;
    font-size: 14px;
    border: 1px solid #D1D5DB;
    border-radius: 2px;
}


.formTitles {
    text-align: left !important;
    font-weight: 700;
    font-size: 14px;
    color: #5C5C5C;
}

.formTitle{
    font-weight: 700;
    font-size: 14px;
    color: #5C5C5C;
}

.btnSubmit {
    margin-top: 20px;
    margin-bottom: 20px;
}

.button-submit {
    width: 511px;
    height: 44px;
    background: #00A991;
    border-radius: 3px;
    border: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
}

.donateMobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .donate-img .bannerDonate{
        margin-top:100px !important;
    }
    .bgImageDonate{
        height: 270px !important;
    }


    .donateDesktop {
        display: none;
    }

    .donateMobile {
        display: block;
    }

    .btnSubmit {
        margin-top: 20px;
    }

    .button-submit {
        width: 300px;
        height: 44px;
        background: #00A991;
        border-radius: 3px;
        border: 0;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
    }

    .formRegisterClose {
        /* margin-top: 50px; */
    }
    .hideHeight {
        display: none;
    }

}

@media screen and (min-width: 800px) {
    .donate-img .bannerDonate{
        margin-top:200px !important;
    }
    .donate-img .bannerDonate .bgImageDonate{
        height: 200px !important;
    }
}


@media screen and (min-width: 1280px) {
   .donate-img .bannerDonate{
        margin-top:180px !important;
    }
    .donate-img .bannerDonate .bgImageDonate{
        height: 400px !important;
    }

    
}