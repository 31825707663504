.bgImageVolunteer {
    position: relative;
}
.bgImageVolunteer .bannerVolunteer{
    margin-top:150px ;
}
.imgBgVolunteer{
    width: 100% ;
}

.vaoDetail{
    font-size: 14px !important;
    text-align: justify;
    text-justify: auto;
    font-weight: 100 !important;
    color:#000;
}

.text-left {
    position: absolute;
    margin-left: 10px;
    top: 25%;
    left:3%;
    color: #ffffff;
    font-weight: 500;
    font-size: 64px;
    margin-bottom: 0;
}

.btn-register {
    margin-left: 10px;
    width: 317px;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 16px;
    background: #00A991;
    border-radius: 100px;
    border: 0;
}

.text-right {
    margin-left: 10px;
    position: absolute;
    top: 35%;
    right: 16px;
    color: #ffffff;
    font-weight: 500;
    font-size: 64px;

}

.textSubTitleDetail{
    font-weight: 400;
font-size: 18px;
color: #FFFFFF;
}

.bgImageVolunteerFooter{
    position: relative;
}
.imgBgVolunteerFoot{
    width: 100%;
}


.text-left-Footer{
    position: absolute;
    margin-left: 10px;
    top: 25%;
    color: #ffffff;
    font-weight: 500;
    font-size: 64px;
    margin-bottom: 0;
}

.btn-register-Footer{
    margin-left: 10px;
    width: 317px;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    padding: 12px 16px;
    background: #00A991;
    border-radius: 100px;
    border: 0;
}




.imgBgVolunteer {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 400px;
}

.volunteerImg {
    width: 100%;
}

.form-check {
    padding: 15px 15px;
}

.userCard {
    /* position: absolute; */
    /* left: 2.08%;
    right: 67.81%;
    top: 52.46%;
    bottom: 39.67%; */
    background: #FFFFFF;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;

}

.titleUser {
    font-weight: 400;
    font-size: 20px;
    color: #00A991;
    text-align: center;
}

.userText {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #334155;
}

.userImg {
    width: 100px;
    height: 100px;
}

.userInfo {
    margin-top: 5px;
    font-weight: 600;
    font-size: 18px;
    color: #00A991;
}

.userInfoDetail {
    font-weight: 400;
    font-size: 14px;
    color: #334155;
}

.userSubCard {
    padding: 20px;
    background: #FFFFFF;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    /* margin: 10px; */
}


 /* form */

 .formTitleRegister {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    color: #000000;
 }
 .subTitleForm{
    font-weight: 400;
    font-size: 14px;
    color: #000000;
 }




 @media screen and (max-width: 360px) {
    .bgImageVolunteer .bannerVolunteer{
        margin-top:100px !important;
    }
    .imgBgVolunteer{
        width: 100% !important;
        height: 160px !important;
    }
    
    .text-left {
        position: absolute;
        margin-left: 5px;
        top: 20% !important;
        left:3% !important;
        color: #ffffff;
        font-weight: 500;
        font-size: 64px;
        margin-bottom: 0;
    }
    
   
 }

 @media screen and (max-width: 412px) {
    .bgImageVolunteer .bannerVolunteer{
        margin-top:0px !important;
    }
    .imgBgVolunteer{
        width: 100% !important;
        height: 160px !important;
    }
    
    .text-left {
        position: absolute;
        margin-left: 5px;
        top: 20% !important;
        left:3% !important;
        color: #ffffff;
        font-weight: 500;
        font-size: 64px;
        margin-bottom: 0;
    }
    
   
 }

 @media screen and (max-width: 320px) {}

 @media screen and (max-width: 375px) {}

 @media screen and (max-width: 414px) {
    .bgImageVolunteer .bannerVolunteer{
        margin-top:0px !important;
    }
    .imgBgVolunteer{
        width: 100% !important;
        height: 160px !important;
    }
    
    .text-left {
        position: absolute;
        margin-left: 5px;
        top: 20% !important;
        left:3% !important;
        color: #ffffff;
        font-weight: 500;
        font-size: 64px;
        margin-bottom: 0;
    }
 }

 @media screen and (max-width: 428px) {
    .bgImageVolunteer .bannerVolunteer{
        margin-top:100px !important;
    }
    .imgBgVolunteer{
        width: 100% !important;
        height: 160px !important;
    }
    
    .text-left {
        position: absolute;
        margin-left: 5px;
        top: 20% !important;
        left:3% !important;
        color: #ffffff;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 0;
    }
    .btn-register {
        margin-left: 5px;
        width: 100px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 12px 16px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
    }


.text-left-Footer{
    position: absolute;
    margin-left: 10px;
    top: 15%;
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0;
}

.btn-register-Footer{
    margin-left: 10px;
    width: 100px;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    padding: 10px 14px;
    background: #00A991;
    border-radius: 100px;
    border: 0;
}

.imgBgVolunteerFoot{
    height: 150px;
}


 }


@media screen and (min-width: 768px) {
    
    .bgImageVolunteer {
        position: relative;
        /* margin-top: 00px, !important; */
    }
    .bgImageVolunteer .bannerVolunteer{
        margin-top:150px !important;
    }

    .text-left-Footer {
        position: absolute;
        margin-left: 10px;
        top: 25%;
        color: #ffffff;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 0;
    }


    .btn-register-Footer{
        margin-left: 10px;
        width: 100px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 12px 16px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
    }

    .bgImageVolunteer {
        position: relative;
        margin-top: -50px !important;
    }

    .imgBgVolunteerFoot{
        height: 200px !important;
    }

    .bgImageVolunteer .imgBgVolunteer{
        height: 200px;
        
    }
    
    .text-left {
        position: absolute;
        margin-left: 10px;
        top: 25%;
        left:3%;
        color: #ffffff;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 0;
    }
    
    .bgImageVolunteer .btn-register {
        margin-left: 10px;
        width: 100px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 12px 16px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
    }
    .vaoDetail{
        font-size: 14px !important;
        text-align: justify;
        text-justify: auto;
        font-weight: 100 !important;
        color:#000;
    }
    .nornalText{
        font-size: 16px !important;
        /* text-align: justify; */
        text-justify: auto;
    }

    .formRegisterClose{
        margin-top:5px
    }

    /* .btnRegister{
        width: 50px !important;
    } */

.hideHeight {
    /* display: none; */
}

}

@media screen and (min-width: 800px) {
    .bgImageVolunteer {
        position: relative;
        margin-top: 0px !important;
    }
    .bgImageVolunteer .bannerVolunteer{
        margin-top:200px !important;
    }

    .imgBgVolunteerFoot{
        height: 400px !important;
    }

    .bgImageVolunteer .imgBgVolunteer{
        /* height: 250px; */
        
    }
    
    .text-left {
        position: absolute;
        margin-left: 10px;
        top: 25%;
        left:3%;
        color: #ffffff;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 0;
    }


}


@media screen and (min-width: 1280px) {
    .vaoDetail{
        font-size: 14px !important;
        text-align: justify;
        text-justify: auto;
        font-weight: 100 !important;
        color:#000;
    }
    
    .bgImageVolunteer {
        position: relative;
        margin-top: 0px !important;
    }
    .bgImageVolunteer .bannerVolunteer{
        margin-top:170px !important;
    }

    .imgBgVolunteerFoot{
        height: 450px !important;
    }

    .bgImageVolunteer .imgBgVolunteer{
        height: 450px;
        
    }
    
    .text-left {
        position: absolute;
        margin-left: 10px;
        top: 25%;
        left:3%;
        color: #ffffff;
        font-weight: 500;
        font-size: 50px;
        margin-bottom: 0;
    }
    .btn-register {
        margin-left: 10px;
        width: 250px !important;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 12px 16px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
    }

}