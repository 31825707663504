.bgImageNews {
    position: relative;
}

.bgImageNews .bannerNews {
    margin-top: 150px !important;
}

.imgNews {
    width: 100% !important;
}

.text-left-new {
    position: absolute;
    width: 100% !important;
    top: 35%;
    /* left: 3%; */
    color: #ffffff;
    font-weight: bold;
    font-size: 64px;
    margin-bottom: 0;
    text-align: center;
}

.news-card {
    padding: 40px;
    border: 1px solid #E8EDF1;
    font-size: 14px;
    border-radius: 3px;
    background: #FFFFFF;
    margin-bottom: 5px;
}

.news-card .news-img {
    width: 100%;
    height: 400%;
}



.button-news-next {
    text-decoration: none !important;
    background-color: #00A991;
    padding: 10px;
    color: #ffffff;
    width: 6%;
    text-align: center;
    font-size: 14px;
}

.button-news-next:hover {
    text-decoration: none !important;
    color: #ffffff;
}

.imgTitle {
    margin-top: -59px;
    background-image: url("../../../image/blog/BlogImg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 40vh;
    position: relative;
}

.imgTitle .blogTitle {
    font-size: 36px;
    color: #ffffff;
    text-align: center;

    /* /* margin-left: 50px; */
    padding-top: 120px;
    font-weight: 500;
}


.blog-detail-title {
    font-weight: 700;
    font-size: 14px;
    color: #252A31;
}

.blog-detail-text {
    font-weight: 400;
    font-size: 14px;
    color: #252A31;
}

.blog-update {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    color: #00A991;
}


.newsFlex{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
}

.newsFlex .newsLetf, .newsRight {
    width: 50%;
}
.newsFlex .newsRight, .newsLetf {
    /* margin: 10px; */
}

.newsFlex .newsRight img{
    margin: 10px;
}
.newsFlex .newsLetf{
 font-size:20px;
 color: #353535;
 font-weight: bold
}





@media screen and (max-width: 360px) {
    .newsFlex .newsLetf{
        font-size:14px;
       }
    .bgImageNews {
        position: relative;
    }

    .bgImageNews .bannerNews {
        margin-top: 100px !important;
    }

    .imgNews {
        width: 100% !important;
        height: 130px !important;

    }

    .text-left-new {
        position: absolute;
        width: 100% !important;
        top: 35%;
        left: 0% !important;
        color: #ffffff;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 0;
        margin: 0!important;
        text-align: center;
    }
}

@media screen and (max-width: 412px) {
    .newsFlex .newsLetf{
        font-size:16px;
       }
    .bgImageNews {
        position: relative;
    }

    .bgImageNews .bannerNews {
        margin-top: 100px !important;
    }

    .imgNews {
        width: 100% !important;
        height: 130px !important;

    }

    .text-left-new {
        position: absolute;
        width: 100% !important;
        top: 35%;
        left: 0% !important;

        color: #ffffff;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 0;
        text-align: center;
    }
}

@media screen and (max-width: 320px) {
    .newsFlex .newsLetf{
        font-size:16px;
       }
    .bgImageNews {
        position: relative;
    }

    .bgImageNews .bannerNews {
        margin-top: 100px !important;
    }

    .imgNews {
        width: 100% !important;
        height: 110px !important;

    }

    .text-left-new {
        position: absolute;
        width: 100% !important;
        top: 35%;
        left: 0% !important;

        color: #ffffff;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 0;
        text-align: center;
    }
}

@media screen and (max-width: 375px) {
    .newsFlex .newsLetf{
        font-size:15px;
       }
    .bgImageNews {
        position: relative;
    }

    .bgImageNews .bannerNews {
        margin-top: 100px !important;
    }

    .imgNews {
        width: 100% !important;
        height: 110px !important;

    }

    .text-left-new {
        position: absolute;
        width: 100% !important;
        top: 35%;
        left: 0% !important;

        color: #ffffff;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 0;
        text-align: center;
    }
}

@media screen and (max-width: 414px) {
    .newsFlex .newsLetf{
        font-size:16px;
       }
    .bgImageNews {
        position: relative;
    }

    .bgImageNews .bannerNews {
        margin-top: 100px !important;
    }

    .imgNews {
        width: 100% !important;
        height: 110px !important;

    }

    .text-left-new {
        position: absolute;
        width: 100% !important;
        top: 35%;
        left: 0% !important;

        color: #ffffff;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 0;
        text-align: center;
    }
}

@media screen and (max-width: 428px) {
    .newsFlex .newsLetf{
        font-size:18px;
       }
    .bgImageNews {
        position: relative;
    }

    .bgImageNews .bannerNews {
        margin-top: 100px !important;
    }

    .imgNews {
        width: 100% !important;
        height: 110px !important;

    }

    .text-left-new {
        position: absolute;
        width: 100% !important;
        top: 35%;
        left: 0% !important;

        color: #ffffff;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 0;
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
    .newsFlex .newsLetf{
        font-size:15px;
       }
    .bgImageNews {
        position: relative;
    }

    .bgImageNews .bannerNews {
        margin-top: 100px !important;
    }

    .imgNews {
        width: 100% !important;
        height: 200px !important;

    }

    .text-left-new {
        position: absolute;
        width: 100% !important;
        top: 35%;
        left: 0% !important;
        color: #ffffff;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 0;
        text-align: center;
    }

    .card-blog {
        padding: 0;
    }

    .imgTitle {
        margin-top: 89px;
        background-image: url("../../../image/blog/BlogImg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 30vh;
        position: relative;
    }

    .imgTitle .blogTitle {
        font-size: 30px;
        color: #ffffff;
        margin-left: 0px;
        text-align: center;
        padding-top: 100px;
        font-weight: 500;
    }

    .subTitleBlog {
        font-size: 14px !important;
    }

    .hideHeight {
        /* display: none; */
        height: 50px !important;

    }
}

@media screen and (min-width: 800px) {
    .bgImageNews {
        position: relative;
    }

    .bgImageNews .bannerNews {
        margin-top: 200px !important;
    }

    .imgNews {
        width: 100% !important;
        height: 200px !important;

    }

    .text-left-new {
        position: absolute;
        width: 100% !important;
        top: 35%;
        left: 0% !important;

        color: #ffffff;
        font-weight: bold;
        font-size: 35px;
        margin-bottom: 0;
        /* text-align: center; */
    }

    .card-blog {
        padding: 0;
    }

    .imgTitle {
        margin-top: 89px;
        background-image: url("../../../image/blog/BlogImg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 30vh;
        position: relative;
    }

    .imgTitle .blogTitle {
        font-size: 30px;
        color: #ffffff;
        margin-left: 0px;
        text-align: center;
        padding-top: 100px;
        font-weight: 500;
    }

    .subTitleBlog {
        font-size: 14px !important;
    }

    .hideHeight {
        /* display: none; */
        height: 50px !important;

    }
}

@media screen and (min-width: 1280px) {
    .newsFlex .newsLetf{
        font-size:20px;
       }
    .bgImageNews {
        position: relative;
    }

    .bgImageNews .bannerNews {
        margin-top: 170px !important;
    }

    .imgNews {
        width: 100% !important;
        height: 300px !important;

    }

    .text-left-new {
        position: absolute;
        width: 100% !important;
        top: 35%;
        left: 0% !important;

        color: #ffffff;
        font-weight: bold;
        font-size: 64px;
        margin-bottom: 0;
        /* text-align: center; */
    } 
}