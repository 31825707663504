body {
    background-color: #fff;
    margin: 0;
    /* font-family: "Noto Sans Lao UI", sans-serif !important; */
    /* font-family: "PhetsarathOT"; */

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  * {
    /* font-family: "PhetsarathOT"; */
    /* font-family: "Noto Sans Lao UI", sans-serif !important; */

  }