.CompanyName {
    text-align: center;
    padding: 10px;
    color: #00072B;
    margin-top: 0px;
    font-weight: bold;
    font-size: 20px;
}

#sidenav-block {
    position: fixed;
    display: block;
    margin-left: 200px;
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    /* box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12); */
    background-color: #00A991;
    /* border-radius: 50px 0px 0px 50px !important; */
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T {
    cursor: default;
    /* box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12); */
    background-color: #00A991;

    /* border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important; */
}

.sc-ifAKCX .iAgGBH .sidenav-navitem---3r9ER .selected---91VwC .highlighted---1vG8Y {
    /* box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);

    border-radius: 50px 0px 0px 50px !important; */
    background-color: #00A991;

}

.sidenav---expanded .sidenav---sidenav-navitem-- :hover {
    /* box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);

    border-radius: 50px 0px 0px 50px !important; */
    background-color: #00A991;

}

/* box search */
.input-icons {
    margin-left: 5%;
}

.input-icons i {
    position: absolute;
    color: lightgray;
}

/* .input-icons {
    width: 2%;
    margin-bottom: 10px;
  } */

.icon {
    padding: 10px;
    /* min-width: 40px; */
}

.input-field {
    width: 300px;
    padding: 5px;
    padding-left: 30px;
    background-color: #00A991;
    border-radius: 10px;
    border: 1px solid lightgray;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12);
}

/* edn box search */