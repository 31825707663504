.contact {
    /* margin-top: 150px; */
    /* padding: 0;
    margin: 0; */
}

.contactMap {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 700px;
    border: 0;
}

.contactMidia {
    margin-top: 170px !important;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #00A991;
}

.contactMidia-Title {
    margin-top: 50px;
    text-align: center;
    font-weight: 700;
    font-size: 34px;
    color: #ffffff;
}

.contactSocial {
    margin-top: 30px;
    text-align: center;
    font-weight: 400;
    font-size: 25px;
    color: #F8FAFC;
}

.contactSocial li a {
    color: #F8FAFC;
    text-decoration: none;

}

.mapAddress {
    margin-top: 170px !important;

}

.contactAddress {
    margin-top: 50px !important;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
}


@media screen and (max-width: 360px) {
    .contactMidia {
        margin-top: 100px !important;
        padding: 0;
        margin: 0;
        width: 100%;
        background-color: #00A991;
    }

    .contactMidia-Title {
        margin-top: 50px;
        text-align: center;
        font-weight: 700;
        font-size: 34px;
        color: #ffffff;
    }

    .contactSocial {
        margin-top: 30px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        color: #F8FAFC;
    }

    .contactAddress {
        margin-top: 50px !important;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
    }

    .mapAddress {
        margin-top: 0px !important;

    }


}

@media screen and (max-width: 412px) {
    .contactMidia {
        margin-top: 100px !important;
        padding: 0;
        margin: 0;
        width: 100%;
        background-color: #00A991;
    }

    .contactMidia-Title {
        margin-top: 50px;
        text-align: center;
        font-weight: 700;
        font-size: 34px;
        color: #ffffff;
    }

    .contactSocial {
        margin-top: 30px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        color: #F8FAFC;
    }

    .contactAddress {
        margin-top: 50px !important;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
    }

    .mapAddress {
        margin-top: 0px !important;

    }
}

@media screen and (max-width: 320px) {}

@media screen and (max-width: 375px) {}

@media screen and (max-width: 414px) {
    .contactMidia {
        margin-top: 100px !important;
        padding: 0;
        margin: 0;
        width: 100%;
        background-color: #00A991;
    }

    .contactMidia-Title {
        margin-top: 50px;
        text-align: center;
        font-weight: 700;
        font-size: 34px;
        color: #ffffff;
    }

    .contactSocial {
        margin-top: 30px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        color: #F8FAFC;
    }

    .contactAddress {
        margin-top: 50px !important;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
    }

    .mapAddress {
        margin-top: 0px !important;

    }
}

@media screen and (max-width: 428px) {
    .contactMidia {
        margin-top: 100px !important;
        padding: 0;
        margin: 0;
        width: 100%;
        background-color: #00A991;
    }

    .contactMidia-Title {
        margin-top: 50px;
        text-align: center;
        font-weight: 700;
        font-size: 34px;
        color: #ffffff;
    }

    .contactSocial {
        margin-top: 30px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        color: #F8FAFC;
    }

    .contactAddress {
        margin-top: 50px !important;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
    }

    .mapAddress {
        margin-top: 0px !important;

    }
}

/* @media screen and (min-width: 768px) {} */



    @media screen and (min-width: 768px) {
        .contactMidia {
            margin-top: 100px !important;
            padding: 0;
            margin: 0;
            width: 100%;
            background-color: #00A991;
        }
        .mapAddress {
            margin-top: 0px !important;
    
        }
    .contactMidia-Title {
        margin-top: 100px;
        text-align: center;
        font-weight: 700;
        font-size: 34px;
        color: #ffffff;
    }

    .contactSocial {
        margin-top: 30px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        color: #F8FAFC;
    }

    .hideHeight {
        display: none;
    }
}