.img-slide {
    position: relative;
    /* height: 500px !important; */
}

.img-caption {
    margin-left: -150px;
}


.img-caption h3 {
    position: absolute;
    top: -450px;
    color: #ffffff;
    font-size: 40px;
}

.btn-mobile-banner {
    float: left;
    margin-top: -150px;
}

.visually-hidden {
    display: none;
}


.carousel-indicators {
    display: none;
}

.title {
    text-align: center;
    /* font-weight: bold; */

}

h2 {
    margin-top: 60px;
    font-weight: bold;
    font-size: 20px;
    color: #000000;

}

.title-Active {
    color: #00A991;
}

.title p {
    color: #000000;
    font-size: 16px;
}


.btn-register-home {
    margin-left: 10px;
    width: 250px;
    color: #ffffff;
    font-weight: 700;
    font-size: 25px;
    padding: 12px 16px;
    background: #00A991;
    border-radius: 100px;
    border: 0;
}

.section-programe-desktop{
    position: relative;
    margin: 0;
    padding: 0;
}


.section-programe-desktop .bgProgrameImg {
    /* position: relative; */
    width: 100%;
    height: 800px;
    margin-top: 10%;
    /* border-top-left-radius: 50%;
    border-top-right-radius: 50%; */
}

.section-programe-desktop .mainCard {
    position: absolute;
    top: 0%;

}

/* .programeContainer{
    position: relative;

} */

.programe-card {
    padding: 20px;
    margin: 20px;
    border: 1px solid #E8EDF1;
    font-size: 14px;
    border-radius: 25px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 27px -19px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 27px -19px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 27px -19px rgba(0, 0, 0, 1);

}

.imgProgrameIcon {
    background: #FFFFFF;
    -webkit-box-shadow: 0px 0px 27px -19px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 0px 27px -19px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 27px -19px rgba(0, 0, 0, 1);
    margin-bottom: 10px;
    border-radius: 18%;
    width: 80px;
    height: 80px;
}

.programe-card h2 {
    margin-top: 0;
}

.programe-card a {
    text-align: center;
    color: #00A991;
    font-weight: 700;
    text-decoration: none;

}

.programe-card h5 {
    font-weight: bold;
}

 .count-text {
    /* margin-top: -310px; */
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8) !important;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    top: 60%;
    /* left:3% !important; */
    /* right:1% !important; */
    width: 100%;
}

.Count-metters {
    background-color: #00A991;
    min-height: 376px;
}

.Count-metters .title {
    color: #ffffff;
    padding-top: 5px;
}

.Count-metters .title h2 {
    color: #ffffff;
    font-size: 48px;
}

.Count-metters .title p {
    color: #ffffff;
    font-size: 18px;
}

.count-text {
    padding-top: -80px;
    text-align: center;
    color: #ffffff;
    border-radius: 30px;
    padding-bottom: 20px;
    left:1% !important;
    right:-1% !important;
    width: 100%;
}

.count-text h3 {
    font-size: 48px;
}

.count-text p {
    font-size: 18px;
}

.news-card {
    padding: 40px;
    border: 1px solid #E8EDF1;
    font-size: 14px;
    border-radius: 30px;
    margin-bottom: 5px;
    border-radius: 25px !important;
}

.news-card .news-img {
    width: 100%;
    height: 400%;
}

.button-news-next {
    text-decoration: none !important;
    background-color: #00A991;
    padding: 10px;
    color: #ffffff;
    width: 6%;
    text-align: center;
    font-size: 14px;
}

.button-news-next:hover {
    text-decoration: none !important;
    color: #ffffff;
}

.partner-card {
    min-height: 206px;
    padding: 20px;
    justify-content: middle;
}

.partner-card .partner-img {
    margin-top: 45px;
    width: 50%;
    height: auto;
}

.section-programe-mobile {
    display: none;
}

.btnProgrmaeDetail {
    background-color: #ffffff;
    border: 2px solid #00A991;
    padding: 5px;
    width: 120px;
    text-align: center;
    color: #000000;
    font-size: 18px;
    border-radius: 50px;
    cursor: pointer;
}

.btnProgrmaeDetail:hover {
    background-color: #00A991;
    color: #fff;
    border: 2px solid #00A991;
    transition: all 0.5s ease-in-out;
}

.titleBig {
    font-size: 44px;
    font-weight: bold;
    text-align: center;
}

.titlePrograme {
    font-size: 20px;
    padding: 20px;
    padding-left: 0;
}

.caption-title {
    font-size: 70px;
    font-weight: 700;
    /* color: #ffffff; */
    text-align: left;
}


@media screen and (max-width: 360px) {
    .titleBig {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    /* .carousel-caption{
        margin-left:20% ;
    } */

   .title .subTitleHome{
        font-size: 14px;

    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-mobile .bgPrograme {
        background-image: url("https://images.indianexpress.com/2021/02/Green-solution.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .section-programe-mobile .bgPrograme .title {
        color: #ffffff;
        font-weight: bold;
    }

    .section-programe-mobile .bgPrograme .cardTotal {
        background-color: rgba(0, 0, 0, 0.5) !important;
        color: #ffffff;
        text-align: center;
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 50px;
        margin-bottom: 10px;
    }
    .hideHeight {
        display: none;
    }

    .carousel-control-next,
    .carousel-control-prev {
        margin-top: 10px;
        display: none;

    }

    .bannerImg .img-caption {
        position: absolute !important;
        display: block !important;
        top: 170% !important;
        color: #ffffff !important;
    }

    

    .img-caption h3 {
        position: absolute;
        top: -200px !important;
        color: #ffffff;
        font-size: 20px !important;

    }

    .banner {
        margin-top: 100px !important;
    }

    .img-caption {
        margin-left: -10px !important;
    }

    .btn-register-home {
        margin-left: 15px !important;
        margin-top: 10px !important;
        float: left;
        width: 120px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }

    .btn-register-home2{
        float: left;
        width: 100px !important;
        color: #00A991 !important;
        font-weight: 700;
        font-size: 14px;
        padding: 5px;
        background: #ffffff !important;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }

    .btn-mobile-banner {
        float: left;
        margin-top: -120px !important;
    }

}
@media screen and (max-width: 412px) {
    .hideHeight {
        display: none;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-mobile .bgPrograme {
        background-image: url("https://images.indianexpress.com/2021/02/Green-solution.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .section-programe-mobile .bgPrograme .title {
        color: #ffffff;
        font-weight: bold;
    }

    .section-programe-mobile .bgPrograme .cardTotal {
        background-color: rgba(0, 0, 0, 0.5) !important;
        color: #ffffff;
        text-align: center;
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 50px;
        margin-bottom: 10px;
    }
    .titleBig {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

   .title .subTitleHome{
        font-size: 14px;

    }
    .carousel-control-next,
    .carousel-control-prev {
        margin-top: 10px;
        display: none;
    }

    .bannerImg .img-caption {
        position: absolute;
        display: block;
        top: 135% !important;
        color: #ffffff;
    }

    .img-caption h3 {
        position: absolute;
        top: -230px !important;
        color: #ffffff;
        font-size: 20px !important;

    }

    .banner {
        margin-top: 100px !important;
    }

    .img-caption {
        margin-left: -50px !important;
    }

    .btn-register-home {
        margin-left: 10px !important;
        /* margin-top: 5px !important; */
        float: left;
        width: 120px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-register-home2{
        float: left;
        width: 100px !important;
        color: #00A991 !important;
        font-weight: 700;
        font-size: 12px;
        padding: 5px;
        background: #ffffff !important;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-mobile-banner {
        float: left;
        margin-top: -170px;
    }

}
@media screen and (max-width: 320px) {
    .hideHeight {
        display: none;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-mobile .bgPrograme {
        background-image: url("https://images.indianexpress.com/2021/02/Green-solution.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .section-programe-mobile .bgPrograme .title {
        color: #ffffff;
        font-weight: bold;
    }

    .section-programe-mobile .bgPrograme .cardTotal {
        background-color: rgba(0, 0, 0, 0.5) !important;
        color: #ffffff;
        text-align: center;
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 50px;
        margin-bottom: 10px;
    }
    .titleBig {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

   .title .subTitleHome{
        font-size: 14px;

    }
    .carousel-control-next,
    .carousel-control-prev {
        margin-top: 10px;
        display: none;
    }

    .bannerImg .img-caption {
        position: absolute;
        display: block;
        top: 160% !important;
        color: #ffffff;
    }

    .img-caption h3 {
        position: absolute;
        top: -190px !important;
        color: #ffffff;
        font-size: 20px !important;
    }
    .banner {
        margin-top: 100px !important;
    }
    .img-caption {
        margin-left: -40px !important;
    }
    .btn-register-home {
        margin-left: 15px !important;
        margin-top: 20px !important;
        float: left;
        width: 90px !important;
        color: #ffffff;
        font-weight: 700;
        font-size: 12px !important;
        padding: 5px !important;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-register-home2{
        float: left;
        width: 90px !important;
        color: #00A991 !important;
        font-weight: 700;
        font-size: 12px;
        padding: 5px;
        background: #ffffff !important;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-mobile-banner {
        float: left;
        margin-top: -110px !important;
    }

    .news-card .topicHome{
        font-size:12px !important;
    }


}
@media screen and (max-width: 375px) {
    .hideHeight {
        display: none;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-mobile .bgPrograme {
        background-image: url("https://images.indianexpress.com/2021/02/Green-solution.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .section-programe-mobile .bgPrograme .title {
        color: #ffffff;
        font-weight: bold;
    }

    .section-programe-mobile .bgPrograme .cardTotal {
        background-color: rgba(0, 0, 0, 0.5) !important;
        color: #ffffff;
        text-align: center;
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 50px;
        margin-bottom: 10px;
    }
    .titleBig {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

   .title .subTitleHome{
        font-size: 14px;

    }
    .carousel-control-next,
    .carousel-control-prev {
        margin-top: 10px;
        display: none;
    }

    .bannerImg .img-caption {
        position: absolute;
        display: block;
        top: 140% !important;
        color: #ffffff;
    }

    .img-caption h3 {
        position: absolute;
        top: -190px !important;
        color: #ffffff;
        font-size: 20px !important;
    }
    .banner {
        margin-top: 100px !important;
    }
    .img-caption {
        margin-left: -30px;
    }
    .btn-register-home {
        margin-left: 10px !important;
        margin-top: 5px !important;
        float: left;
        width: 120px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-mobile-banner {
        float: left;
        margin-top: -120px !important;
    }

}

@media screen and (max-width: 390px) {
    .btn-mobile-banner {
        float: left;
        margin-top: -110px !important;
    }
}

@media screen and (max-width: 414px) {
    .hideHeight {
        display: none;
    }
    .titleBig {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    
    .section-programe-mobile .bgPrograme {
        background-image: url("https://images.indianexpress.com/2021/02/Green-solution.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .section-programe-mobile .bgPrograme .title {
        color: #ffffff;
        font-weight: bold;
    }

    .section-programe-mobile .bgPrograme .cardTotal {
        background-color: rgba(0, 0, 0, 0.5) !important;
        color: #ffffff;
        text-align: center;
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 50px;
        margin-bottom: 10px;
    }
   .title .subTitleHome{
        font-size: 14px;

    }
    .carousel-control-next,
    .carousel-control-prev {
        margin-top: 10px;
        display: none;
    }

    .bannerImg .img-caption {
        position: absolute;
        display: block;
        top: 135% !important;
        color: #ffffff;
    }

    .img-caption h3 {
        position: absolute;
        top: -190px !important;
        color: #ffffff;
        font-size: 20px !important;
    }
    .banner {
        margin-top: 100px !important;
    }
    .img-caption {
        margin-left: -55px !important;
    }
    .btn-register-home {
        margin-left: 10px !important;
        /* margin-top: 5px !important; */
        float: left;
        width: 120px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-register-home2{
        float: left;
        width: 90px !important;
        color: #00A991 !important;
        font-weight: 700;
        font-size: 12px;
        padding: 5px;
        background: #ffffff !important;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-mobile-banner {
        float: left;
        margin-top: -130px;
    }

}
@media screen and (max-width: 428px) {
    .hideHeight {
        display: none;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-mobile .bgPrograme {
        background-image: url("https://images.indianexpress.com/2021/02/Green-solution.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .section-programe-mobile .bgPrograme .title {
        color: #ffffff;
        font-weight: bold;
    }

    .section-programe-mobile .bgPrograme .cardTotal {
        background-color: rgba(0, 0, 0, 0.5) !important;
        color: #ffffff;
        text-align: center;
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 50px;
        margin-bottom: 10px;
    }
    .titleBig {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

   .title .subTitleHome{
        font-size: 14px;

    }
    .carousel-control-next,
    .carousel-control-prev {
        margin-top: 10px;
        display: none;
    }

    .bannerImg .img-caption {
        position: absolute;
        display: block;
        top: 135% !important;
        color: #ffffff;
    }

    .img-caption h3 {
        position: absolute;
        top: -190px !important;
        color: #ffffff;
        font-size: 20px !important;
    }
    .banner {
        margin-top: 100px !important;
    }
    .img-caption {
        margin-left: -55px !important;
    }
    .btn-register-home {
        margin-left: 10px !important;
        /* margin-top: 5px !important; */
        float: left;
        width: 120px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-register-home2{
        float: left;
        width: 90px !important;
        color: #00A991 !important;
        font-weight: 700;
        font-size: 12px;
        padding: 5px;
        background: #ffffff !important;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-mobile-banner {
        float: left;
        margin-top: -120px !important;
    }

}
 @media screen and (min-width: 768px) {
    .hideHeight {
        display: none;
    }
    .section-programe-desktop {
        display: none;
    }
    .section-programe-mobile {
        display: block;
    }
    .section-programe-mobile .bgPrograme {
        background-image: url("https://images.indianexpress.com/2021/02/Green-solution.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .section-programe-mobile .bgPrograme .title {
        color: #ffffff;
        font-weight: bold;
    }

    .section-programe-mobile .bgPrograme .cardTotal {
        background-color: rgba(0, 0, 0, 0.5) !important;
        color: #ffffff;
        text-align: center;
        padding: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 50px;
        margin-bottom: 10px;
    }
    .carousel-control-next,
    .carousel-control-prev {
        margin-top: 10px;
        display: none;
    }

    .titleBig {
        font-size: 25px;
        font-weight: bold;
        text-align: center;
    }

   .title .subTitleHome{
        font-size: 16px;

    }

    .bannerImg .img-slide{
        margin-top: 100px !important;
        height: 350px !important;
    }

    .bannerImg .img-caption {
        position: absolute;
        display: block;
        top: 120% !important;
        color: #ffffff;
    }

    .img-caption h3 {
        position: absolute;
        top: -350px !important;
        color: #ffffff;
        font-size: 35px !important;
    }
  
    .img-caption {
        margin-left: -80px !important;
    }
    .btn-register-home {
        float: left;
        width: 120px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }

    .btn-register-home2{
        float: left;
        width: 120px;
        color: #00A991;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #ffffff !important;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }

    .btn-mobile-banner {
        float: left;
        margin-top: -210px;
    }

} 
@media screen and (min-width: 820px) {
    .hideHeight {
        display: none;
    }

    .carousel-control-next,
    .carousel-control-prev {
        margin-top: 10px;
        display: none;
    }
   

    .bannerImg .img-slide{
        margin-top: 160px !important;
        height: 300px !important;
    }

    .bannerImg .img-caption {
        position: absolute;
        display: block;
        top: 110% !important;
        color: #ffffff;
    }

    .img-caption h3 {
        position: absolute;
        top: -350px !important;
        color: #ffffff;
        font-size: 35px !important;
    }
   
    .img-caption {
        margin-left: -90px;
    }
    .btn-register-home {
        float: left;
        width: 120px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }

    .btn-register-home2{
        float: left;
        width: 120px;
        color: #00A991;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        /* background: linear-gradient(to bottom right, #50d5b7 0%, #067d68 100%); */

        background: #ffffff !important;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-mobile-banner {
        float: left;
        margin-top: -230px;
    }

} 

@media screen and (min-width: 800px) {
    .hideHeight {
        display: none;
    }

    .carousel-control-next,
    .carousel-control-prev {
        margin-top: 10px;
        display: none;
    }
   

    .bannerImg .img-slide{
        margin-top: 160px !important;
        height: 400px !important;
    }

    .bannerImg .img-caption {
        position: absolute;
        display: block;
        top: 115% !important;
        color: #ffffff;
    }

    .img-caption h3 {
        position: absolute;
        top: -350px !important;
        color: #ffffff;
        font-size: 35px !important;
    }
   
    .img-caption {
        margin-left: -90px;
    }
    .btn-register-home {
        float: left;
        width: 120px;
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-register-home2{
        float: left;
        width: 120px;
        color: #00A991;
        font-weight: 700;
        font-size: 14px;
        padding: 6px;
        background: #ffffff !important;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-mobile-banner {
        float: left;
        margin-top: -230px;
    }

} 

@media screen and (min-width: 1280px) {
    .section-programe-desktop {
        display: block;
    }
    .section-programe-mobile {
        display: none;
    }
    .bannerImg .img-slide{
        margin-top: 160px !important;
        height: 600px !important;
    }
    .bannerImg .img-caption {
        position: absolute;
        display: block;
        top: 100% !important;
        color: #ffffff;
    }
    .btn-register-home {
        float: left;
        width: 120px;
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        padding: 10px;
        background: #00A991;
        border-radius: 100px;
        border: 0;
        margin: 0;
    }
    .btn-register-home2{
        float: left;
        width: 300px;
        color: #00A991;
        font-size: 20px;
        padding: 20px;
        background: #ffffff !important;
        border-radius: 100px;
        font-weight:bold;
        border: 0;
        margin: 0;
    }
    .img-caption {
        margin-top: 20px;
        margin-left: -180px !important;
    }



}