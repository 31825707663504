.container {
    position: relative;
    text-align: center;
    color: white;
}

/* .imgBg-about-pro{
    height: 450px;
} */

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 40px;
    color: #ffffff;
    font-weight: bold;
}

.bottom-center {
    position: absolute;
    top: 88%;
    left: 29%;
    right: 15%;
}

.programe {
    color: #252A31;
    background: #FFFFFF;
    box-shadow: inset 0px -2px 0px #E8EDF1;
    padding: 12px 16px;
    cursor: pointer;
}

.action-programe {
    border-bottom: 2px solid #00A991;
}

.pro1Image {
    width: 80%;
    height: 400px;
    padding: 50px;
    border-radius:20px !important;
}

.proTitle {
    text-align: center;
    /* margin-top:80px; */
    font-weight: 700;
    font-size: 36px;
    color: #475569;
    font-size: 18px;
}
.proImage{
    width: 100%;
    height:auto;
    border-radius:10px;
}

.proDetail{
    margin-top:58px;
    font-weight: 400;
    font-size: 16px;
    color: #0F172A;
    text-align: justify;
    text-justify: auto;
    /* text-align: left; */
}

.proDetailTitle{
    margin-top:10px;
    font-weight: 400;
    font-size: 16px;
    color: #0F172A;
    text-align: justify;
    text-justify: auto;
    text-align: center;
    /* text-align: left; */
}
@media screen and (max-width: 768px) {

.proFix1{
    margin-top:230px !important;
}

.proTitle{
    margin-top:30px !important;
    font-size:18px !important;
}

.proTitleFix{
    margin-top:50px !important;
    font-size:18px !important;
}

.proDetail{
    margin-top:20px;
    font-weight: 400;
    font-size: 14px;
    color: #0F172A;
    text-align: left;
    margin-left:0px;
    margin-right:0px;
    text-align: justify;
    text-justify: auto;
}

    .proImage {
        width: 100%;
        height: auto;
        /* padding: 50px; */
        border-radius:20px !important;
    }

    .pro1Image2{
        margin-top:20px;
        width: 100%;
        height: 400px;
        /* padding: 50px; */
        border-radius:20px !important;
    }

    .proTitle {
        text-align: center;
        margin-top:10px;
        font-weight: 500;
        font-size: 36px;
        color: #475569;
    }

    .bottom-center {
        position: absolute;
        top: 88%;
        left: 25%;
        right: 25%;
    
    }
    
    .imgBgVolunteer{
        position: relative;

        height: 200px;
    }
/* 
    .proMainTitle{
        margin-top: 30px !important;
    } */

    .centered {
        position: absolute;
        top: 60% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        font-size: 30px;
        color: #ffffff;
    }

        .hideHeight {
            display: none;
        }


    }

    /* @media screen and (min-width: 820px) {

    } */