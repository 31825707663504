

 .menu-main {
      /* position:fixed; */
     z-index: 99 !important;
     width: 100%;
     padding: 0;
     margin: 0;
     background-color: #FFFFFF;

 }

 .menu-main .menu-head-main {
     display: flex;
     flex-direction: row;
     position:sticky;


 }

 .menu-main .menu-head-main .menu-head-main-left {
     width: 70%;
     padding: 10px;

 }

 .menu-main .menu-head-main .menu-head-main-left img {
     width: 100px;
     height: 90px;
     margin-left: 30px;
 }

 .menu-main .menu-head-main .menu-head-main-right {
     width: 30%;
     padding-left: 40px;
 }

 .menu-main .menu-head-main .menu-head-main-right a>img {
     width: 33px;
     height: 33px;
     /* margin-top: 5px; */
     margin-left: 15px;
     /* float: right !important; */
     /* position: absolute; */

 }

 .menu-main .menu-head-main .menu-head-main-right .button-header {
     height: 44px;
     margin-top: 25px;

 }

 .menu-main .menu-head-main .menu-head-main-right .button-donate {
     /* background-color: #00A991 !important; */
     background: linear-gradient(to bottom right, #50d5b7 0%, #067d68 100%);
     border-radius: 3px !important;
     border: 0;
     color: #ffffff;
     font-weight: 700;
     font-size: 14px;
     /* float: right !important; */
     /* position: absolute; */


 }

 .menu-main .menu-head-main .menu-head-main-right .button-tree-donate {
     background-color: #ffffff;
     border-radius: 3px !important;
     border: 2px solid #00A991;
     color: #00A991;
     font-weight: 700;
     font-size: 14px;
     margin-left: 15px;
     /* float: right !important; */
     /* position: absolute; */


 }

 .menu-main .menu-head-main .menu-head-main-right .button-tree-donate:hover {
     background-color: #c2fff4;
     border-radius: 3px !important;
     border: 2px solid #00A991;
     color: #00A991;
     font-weight: 700;
     font-size: 14px;

 }



 .menu-main .menu-list {
     display: flex;
     flex-direction: row;
     border-top: 2px solid #00A991;
     padding: 20px;

 }

 .menu-main .menu-list .menu-link {
     width: 16.66%;
     text-align: center;
     font-weight: 700;
     font-size: 14px;

 }

 .menu-main .menu-list .menu-link .item-link {
     color: #334155;
     font-weight: bold;
     font-size: 14px;

 }


 .menu-main .menu-list .menu-link .active-link {
     text-decoration: none;
     font-weight: bold;
     color: #FFF;


 }

 .menu-main .menu-list .menu-link .item-link:hover {
     color: #00A991;
     font-weight: bold;
     font-size: 14px;
     text-decoration: none;
 }


 /* progrome */

 .dropbtn {
    background-color: white;
    color: white;
    /* padding: 16px; */
    font-size: 16px;
    border: none;
    cursor: pointer;
    z-index: 9999 !important;

  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    z-index: 9 !important;

  }
  
  .dropdown-content {
     z-index: 9999 !important;
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  
  .dropdown-content a {
     z-index: 9999 !important;

    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1;
    z-index: 9999 !important;

}
  
  .dropdown:hover .dropdown-content {
    display: block;
    z-index: 9999 !important;

  }
  
  .dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
    color: #00A991;
    z-index: 9999 !important;

  }


 /* navbar two */

 *,
 *::after,
 *::before {
     margin: 0;
     box-sizing: border-box;
 }

 html {
     font-size: 62.5%;
 }

 body {
     font-size: 1.6rem;
 }

 li {
     list-style: none;
 }

 a {
     text-decoration: none;
     color: rgb(204, 204, 204);
 }

 .header{
    display: none;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px 60px;
    color: #000000;
    z-index: 1000;
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
  }
  
  .logo {
    font-size: 30px;
    font-weight: 700;
  }
  .nav_ico {
    display: inline-block;
    position: relative;
  }
  .nav_ico label{
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }
  .nav_ico span{
    display: block;
    height: 4px;
    width: 30px;
    background: #000000;
    margin: 4px 0
  }
  
  .nav-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 998;
    background: transparent;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }
  .nav-overlay.active {
    background: rgba(0, 0, 0, 0.7);
    pointer-events: auto;
  }
  
  
  #menu_trigger{
    position: fixed;
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
  .close_icon{
    color: #000000;
    font-size: 60px;
    position: relative;
    position: absolute;
    left: 20px;
    top: 20px;
    line-height: 0.7;
    transform: rotate(45deg);
  }
  .close_icon input{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    cursor: pointer;
  }
  .side_nav{
    /* hidden: fixed; */
    position: fixed;
    right: 0;
    top: 0;
    width: 260px;
    height: 100vh;
    background: #ffffff;
    z-index: 1001;
    padding-top: 100px;
    transition: all 0.3s ease-in-out;
    transform: translateX(100%);
  }
  #menu_trigger:checked + .side_nav{
    transform: translateX(0%);
  }
  .side_nav ul{
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .side_nav ul li{
    margin: 0;
  }
  .side_nav ul li a{
    display: block;
    color: #000000;
    font-size: 20px;
    text-decoration: none;
    padding: 12px 30px;
    /* transition: all 0.3s ease-in-out; */
  }
  /* .side_nav ul li a:hover{
    background: #00A991;
    color: #ffffff;
  } */
  

  .dropdown .dropbtn{
    font-weight: 400;
    font-size: 14px;
    color: #252A31;
  }
  .dropdown .dropdown-content{
    font-size: 14px;
  }

 

 @media screen and (max-width: 768px) {
  .btnDonateSideNav{
    padding:20px;
  }
  .button-header {
    height: 44px;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    margin-bottom: 10px;


}
.btnDonateSideNav .button-donate {
  /* background-color: #00A991 !important; */
  background: linear-gradient(to bottom right, #50d5b7 0%, #067d68 100%);
  border-radius: 3px !important;
  border: 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  /* float: right !important; */
  /* position: absolute; */


}

.btnDonateSideNav .button-tree-donate {
  background-color: #ffffff;
  border-radius: 3px !important;
  border: 2px solid #00A991;
  color: #00A991;
  font-weight: 700;
  font-size: 14px;

}

.btnDonateSideNav .button-tree-donate:hover {
  background-color: #c2fff4;
  border-radius: 3px !important;
  border: 2px solid #00A991;
  color: #00A991;
  font-weight: 700;
  font-size: 14px;
}



    .langueage{
      margin-top:100px;
    }

    .menu-main {
        display: none;

        
    }
     .dropbtnProfile{
      /* margin-top:40px !important; */
      font-weight: 400;
      font-size: 20px;
      color: #252A31;
      border:0px;
      background: #ffffff;

    }

    .dropdownProfile .dropdown-content a{
      margin-top: 2px;
      width: 200px;
      padding:15px;
    }

    .header{
      position: fixed !important;
      height:100px;
      /* display: none; */
      background-color: #ffffff;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 20px 60px;
      color: #000000;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-bottom: 3px solid #00A991;

    }
    
    .logo {
      margin-left: -25px;
      margin-top:0;
      font-size: 30px;
      font-weight: 700;
      border-radius:50% !important;
    }
    .nav_ico {
      margin-right: -25px;
      margin-top:0px;
      display: inline-block;
      position: relative;
    }
    .nav_ico label{
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;
    }
    .nav_ico span{
      display: block;
      height: 4px;
      width: 30px;
      background: #000000;
      margin: 4px 0
    }

    /* .side_nav ul> li .item-link{
      font-size: 15px !important;

    } */

   

    .nav-overlay {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 998;
      background: transparent;
      transition: all 0.3s ease-in-out;
      pointer-events: none;
    }
    .nav-overlay.active {
      background: rgba(0, 0, 0, 0.7);
      pointer-events: auto;
    }
    
    
    #menu_trigger{
      position: fixed;
      opacity: 0;
      pointer-events: none;
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }
    .close_icon{
      color: #000000;
      font-size: 50px;
      position: relative;
      position: absolute;
      left: 20px;
      top: 30px;
      line-height: 0.4;
      transform: rotate(45deg);
    }
    .close_icon input{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      margin: 0;
      cursor: pointer;
    }
    .side_nav{
      position: fixed;
      right: 0;
      top: 0;
      width: 260px;
      height: 100vh;
      background: #ffffff;
      z-index: 1001;
      padding-top: 100px;
      transition: all 0.3s ease-in-out;
      transform: translateX(100%);
    }
    #menu_trigger:checked + .side_nav{
      transform: translateX(0%);
    }
    .side_nav ul{
      padding: 0;
      margin: 0;
      list-style: none;
    }
    .side_nav ul li{
      margin: 0;
    }
    .side_nav ul li a{
      margin-top: -30px;
      
      display: block;
      color: #000000;
      font-size: 15px;
      text-decoration: none;
      padding: 12px;
      transition: all 0.3s ease-in-out;
    }

    .side_nav .dropdown{
      /* margin-top: -40px; */
    }

    /* .side_nav .dropbtn{
      color: #000000 !important;
      font-size: 15px !important;
      margin-left: 0px;
      width: 100%;
    } */
    .side_nav .dropdown-content a{
      margin-top: 2px;
      width: 200px;
      padding:15px;
    }

    /* .side_nav ul li a:hover{
      background: #00A991;
      color: #fff;
    } */
    
   


  }

.login-form{
    padding: 40px;
    padding-top: 0;
}

.login-title{
    font-weight: 700;
font-size: 28px;
color: #000000;
}

 .btn-login{
     background-color: #00A991;
     color: #ffffff;
     border-radius: 3px;
     border:0px;
     width: 100%;
     padding: 12px 16px;
 }


@media screen and (max-width: 820px) {

  .menu-main .menu-head-main .menu-head-main-left {
    width: 40%;
    padding: 10px;

}

.menu-main .menu-head-main .menu-head-main-right {
    width: 60%;
    padding-left: 40px;
}
}


@media screen and (max-width: 834px) {

  .menu-main .menu-head-main .menu-head-main-left {
    width: 50%;
    padding: 10px;

}

.menu-main .menu-head-main .menu-head-main-right {
    width: 50%;
    padding-left: 40px;
}
}

@media screen and (max-width: 800px) {

  .menu-main .menu-head-main .menu-head-main-left {
    width: 45%;
    padding: 10px;

}

.menu-main .menu-head-main .menu-head-main-right {
    width: 55%;
    padding-left: 40px;
}
}