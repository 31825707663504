.bgImage-about-pro {
    position: relative;
}

.bgImage-about-pro .banner-about-pro {
    margin-top: 150px !important;
}

.imgBg-about-pro {
    width: 100% !important;
}

.bgImage-about-pro .banner-about-pro .centered-about-pro {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 40px;
    color: #ffffff;
    font-weight: bold;
}

.container-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    top: 90%;
    position: absolute;
    width: 100%;
}

.container-list .list-items {
    width: 16%;
    text-align: center;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: inset 0px -2px 0px #E8EDF1;
    cursor: pointer;
    color: #252A31;

}

.container-list .list-items:hover {
    border-bottom: 2px solid #00A991;
}


.titleDetail {
    font-weight: 400;
    font-size: 12px;
    color: rgba(33, 44, 79, 0.7);

}

.videoAbout {
    width: 100%;
    padding: 10px;
}

.titleMission {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    margin-top: 10%;
}

.MissionDetail {
    font-weight: 400;
    font-size: 16px;
    color: rgba(33, 44, 79, 0.7);
    text-align: justify;
    text-justify: inter-word;
    text-align: left !important;


}

.imgMission {
    width: 100%;
    height: 250px;
}

.imgMission2 {
    width: 60%;
    height: 200px;
}

.imgMission3 {
    width: 60%;
    height: 200px;
}

.imgMission4 {
    width: 60%;
    height: 250px;
}

.memberCard {
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #E8EDF1;
    border-radius: 3px;
    margin-bottom: 10px;
}

.memberImage {
    width: 200px;
    height: 200px;
}

.memberInfo {
    font-weight: 700;
    font-size: 14px;
    color: #252A31;
}

.memberPosition {
    font-weight: 400;
    font-size: 14px;
    color: #252A31;
}

/* ul> li {
    list-style-type:disc;
    margin-left:20px;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    color: rgba(33, 44, 79, 0.7);
} */
.missionDesktop{
    display:block;
}

.missionMobile {
    display: none;
}

.hideHeight {
    height: 150px;
}







@media screen and (max-width: 360px) {
    .bgImage-about-pro .banner-about-pro {
        margin-top: 100px !important;
    }
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 150px !important;
        width: 100% !important;
    }
    .bgImage-about-pro .banner-about-pro .centered-about-pro {
        font-size: 25px;
    }
    .aboutTop {
        margin-top: 230px !important;
    }

    .container-list {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
        width: 100% !important;

    }

    .missionDesktop {
        display: none;
    }

    .missionMobile {
        display: block
    }

    .container-list .list-items {
        flex: 100%;
        width: 80% !important;
        text-align: center !important;
        background-color: #ffffff;
        padding: 10px;
        box-shadow: inset 0px -2px 0px #E8EDF1;
        cursor: pointer;
        color: #252A31;
    }
}

@media screen and (max-width: 412px) {

    .bgImage-about-pro .banner-about-pro {
        margin-top: 100px !important;
    }
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 150px !important;
        width: 100% !important;
    }
    .bgImage-about-pro .banner-about-pro .centered-about-pro {
        font-size: 25px;
    }
    .aboutTop {
        margin-top: 230px !important;

    }
    .missionDesktop {
        display: none;
    }

    .missionMobile {
        display: block
    }

    .container-list {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
        width: 100% !important;

    }

    .container-list .list-items {
        flex: 100%;
        width: 80% !important;
        text-align: center !important;
        background-color: #ffffff;
        padding: 10px;
        box-shadow: inset 0px -2px 0px #E8EDF1;
        cursor: pointer;
        color: #252A31;
    }
}

@media screen and (max-width: 320px) {
    .bgImage-about-pro .banner-about-pro {
        margin-top: 100px !important;
    }
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 150px !important;
        width: 100% !important;
    }
    .bgImage-about-pro .banner-about-pro .centered-about-pro {
        font-size: 25px;
    }


    .aboutTop {
        margin-top: 230px !important;

    }
    .missionDesktop {
        display: none;
    }

    .missionMobile {
        display: block
    }
    .container-list {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
        width: 100% !important;
        z-index: 1000;

    }

    .container-list .list-items {
        flex: 100%;
        width: 80% !important;
        text-align: center !important;
        background-color: #ffffff;
        padding: 10px;
        box-shadow: inset 0px -2px 0px #E8EDF1;
        cursor: pointer;
        color: #252A31;
    }
}

@media screen and (max-width: 375px) {
    .bgImage-about-pro .banner-about-pro {
        margin-top: 100px !important;
    }
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 150px !important;
        width: 100% !important;
    }
    .bgImage-about-pro .banner-about-pro .centered-about-pro {
        font-size: 25px;
    }
    .aboutTop {
        margin-top: 230px !important;

    }
    .missionDesktop {
        display: none;
    }

    .missionMobile {
        display: block
    }
    .container-list {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
        width: 100% !important;

    }

    .container-list .list-items {
        flex: 100%;
        width: 80% !important;
        text-align: center !important;
        background-color: #ffffff;
        padding: 10px;
        box-shadow: inset 0px -2px 0px #E8EDF1;
        cursor: pointer;
        color: #252A31;
    }
}

@media screen and (max-width: 414px) {
    .bgImage-about-pro .banner-about-pro {
        margin-top: 100px !important;
    }
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 150px !important;
        width: 100% !important;
    }
    .bgImage-about-pro .banner-about-pro .centered-about-pro {
        font-size: 25px;
    }
    .aboutTop {
        margin-top: 230px !important;

    }
    .missionDesktop {
        display: none;
    }

    .missionMobile {
        display: block
    }
    .container-list {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
        width: 100% !important;

    }

    .container-list .list-items {
        flex: 100%;
        width: 80% !important;
        text-align: center !important;
        background-color: #ffffff;
        padding: 10px;
        box-shadow: inset 0px -2px 0px #E8EDF1;
        cursor: pointer;
        color: #252A31;
    }
}

@media screen and (max-width: 428px) {
    .bgImage-about-pro .banner-about-pro {
        margin-top: 100px !important;
    }
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 150px !important;
        width: 100% !important;
    }
    .bgImage-about-pro .banner-about-pro .centered-about-pro {
        font-size: 25px;
    }
    .aboutTop {
        margin-top: 230px !important;

    }
    .missionDesktop {
        display: none;
    }

    .missionMobile {
        display: block
    }
    .container-list {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center;
        width: 100% !important;

    }

    .container-list .list-items {
        flex: 100%;
        width: 80% !important;
        text-align: center !important;
        background-color: #ffffff;
        padding: 10px;
        box-shadow: inset 0px -2px 0px #E8EDF1;
        cursor: pointer;
        color: #252A31;
    }
}

@media screen and (min-width: 768px) {
    .bgImage-about-pro .banner-about-pro {
        margin-top: 100px !important;
    }
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 250px !important;
        width: 100% !important;
    }
    .bottom-center {
        position: absolute;
        top: 88%;
        left: -12%;
        font-size: 16px !important;
        /* width: 550px; */
        text-align: center;
    }

    .HeadMission {
        font-size: 18px;
    }

    .aboutTop {
        margin-top: -30px;
        /* padding-bottom: 10px !important; */
    }

    .mission1 {
        margin-top: -20px !important;
    }

    .titleMission {
        font-size: 18px;
        font-weight: bold;
    }

    .MissionDetail {
        font-size: 16px;
        text-align: justify !important;
        text-justify: auto !important;
    }

    .aboutTop .titleDetail {
        font-size: 16px;
        text-align: justify !important;
        text-justify: auto !important;


    }

    .aboutTop .storyUl {
        font-size: 14px !important;
    }

    .imgMission {
        width: 100%;
        height: 200px;
        font-size: 16px;
    }

    .imgMission2 {
        width: 60%;
        height: 200px;
    }

    .imgMission3 {
        width: 60%;
        height: 180px;
    }

    .imgMission4 {
        width: 60%;
        height: 200px;
    }

    .videoAbout {
        height: 400px;
        padding: 0px;

    }


    .missionDesktop {
        display: block;
    }

    .missionMobile {
        display: none
    }

    .missionMobile .teamDetail {
        font-size: 14px;
        text-align: justify !important;
        text-justify: auto !important;
        /* text-align: left !important; */
    }

    .missionMobile .listTeam {
        font-size: 14px;
        text-align: justify !important;
        text-justify: auto !important;
    }

    .nornalText {
        font-size: 16px;
        text-align: justify !important;
        text-justify: auto !important;
    }

    .hideHeight {
        /* display: none; */
        height: 50px !important;

    }

    /* .heightMobile{
    height: 200px !important;
} */

}

@media screen and (min-width: 820px) {
    .aboutTop {
        margin-top: -40px;
        /* padding-bottom: 10px !important; */
    }
}

@media screen and (min-width: 800px) {
    .aboutTop {
        margin-top: -40px;
        /* padding-bottom: 10px !important; */
    }
    .bgImage-about-pro {
        position: relative;
    }
    
 
    
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 250px !important;
        width: 100% !important;
    }
    
   
}

@media screen and (min-width: 834px) {
    .aboutTop {
        margin-top: -40px;
        /* padding-bottom: 10px !important; */
    }
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 250px !important;
        width: 100% !important;
    }
}

@media screen and (min-width: 1280px) {
    .aboutTop {
        margin-top: -40px;
        /* padding-bottom: 10px !important; */
    }
    .bgImage-about-pro .banner-about-pro .imgBg-about-pro {
        height: 430px !important;
        width: 100% !important;
    }
    
}