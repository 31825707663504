.containerStep {
  width: 100%;
  color: white;
  background: #2979FF;
  /* font-family: 'Roboto', sans-serif; */
  /* margin-top: 20%; */
}

.progressbar {
  counter-reset: step;
}

.progressbar li {
  position: relative;
  list-style: none;
  float: left;
  width: 25%;
  text-align: center;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}

/* Circles */
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  border: 1px solid #D1D5DB;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #D1D5DB;

  /* Center # in circle */
  line-height: 39px;
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #D1D5DB;
  top: 30px;
  /*half of height Parent (li) */
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active:before {
  background: #ffffff;
  border:1px solid #3F83F8;
  color: #3F83F8;
  font-size:25px;
  padding-top: 5px;
  font-weight:bold;
  content: "*";
}


.progressbar li.activeDone:before {
  background: #3F83F8;
  border:1px solid #3F83F8;
  color: #ffffff;
  font-size:20px;
  font-weight:bold;
  content: "✔";
}


.progressbar li.active+li:after {
  background: #3F83F8;
}
.progressbar li.activeDone+li:after {
  background: #3F83F8;

}

.titleAccount {
  margin-top:40px;

  color: #000000;
  font-weight: 700;
  font-size: 34px;
  text-align: center;

}
.textLabel{
  font-weight: 700;
font-size: 14px;
  text-align: left;
  color: #5C5C5C;
}

.cardProfile{
  padding:10px;
  background: #FFFFFF;
/* Cloud / Dark */
height: 150px;
border: 1px solid #E8EDF1;
border-radius: 3px;
}

.cardProfile .profileTitle{
  font-weight: 700;
  font-size: 14px;
  color: #252A31;
}

.cardProfile .profileSubTitle{
  font-weight: 500;
font-size: 64px;
  color: #000000;
}

.certificateTitle{
  padding:20px;
  font-weight: 700;
font-size: 28px;
color: #000000;
}

.bgPlantDonate{
  padding: 0;
  margin: 0;
  background-image: url("../../../image/donate/plantDonate.png");
  /* margin-top: 100px; */

}



@media screen and (max-width: 768px) {
  .cerImage{
    width: 100% !important;
    height: auto !important;
  }
  .titleAccount {
    margin-top:40px;
    color: #000000;
    font-weight: 700;
    font-size: 20px;
  }

.activeHidden{
  display: none;
}


.hideHeight {
  display: none;
}

}