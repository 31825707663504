.card-setting {
    background: #F8F8F8;
    box-shadow: 0px 4px 4px rgba(132, 132, 132, 0.25);
    border-radius: 10px;
    cursor: pointer;
}

.card-setting .card-body {
    display: flex;
    padding: 20px;
    min-height: 155px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-setting .card-body .settingIcon{
    font-weight: 900;
    font-size: 40px;
    color: #64748B;
    margin-bottom: 10px;
}

.card-setting .card-body .settingTitle {
    font-weight: 700;
    font-size: 18px;
    color: #5C5C5C;
}

.card-setting .card-body .settingSubTitle {
    font-weight: 400;
    font-size: 14px;
    color: #5C5C5C;
}

