.login-body {
    background-image: url("../../../image/admin/bgLogin.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-card {
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    min-height: 300px;
    padding-top: 5px;
    padding-left: 50px;
    padding-right: 50px;
}

.login-card .login-subTitle{
    font-weight: 700;
    font-size: 14px;
    color: #5C5C5C;
    
}

.login-card .login-title {
    font-size: 25px;
    text-align: center;
    font-weight: 700;
    color: #ffffff;
}

.login-card .login-button {
    background-color: #1890FF;
    border-radius: 3px;
    width: 250px;
    padding: 5px;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    border:0;
}