@font-face {
  font-family: "wght";
  src: url(../public/assets/font/Inter/Inter-VariableFont_slnt\,wght.ttf) format("truetype");
}

*,
body,
label,
Button,
div {
  margin: 0;
  /* font-family: "NotoSansLao" !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bigTitle {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

.middleTitle {
  font-size: 18px;
  color:#000000;
}

.smallTitle {
  font-size: 16px;
}

.textDetail{
  font-size: 16px;
  color:#000000;
  text-align: justify;
  text-justify: inter-word;
  font-weight: normal;
}

.textDetailVolunteer{
  font-size: 16px;
  color:#000000;
  font-weight: normal;
}